'use client';
import { Review } from '@/services/review.service';
import clsx from 'clsx';
import Image from 'next/image';
import { FC, useState } from 'react';
import Quote from '../../../public/icons/Group.svg';
import RatingStar from '../../../public/icons/Vector.svg';
import Logo3T from '../../../public/logos/stratt-logo-ttt.svg';

const ReviewCard: FC<{
    review: Review;
    index: number;
    isHideOnMobile: boolean;
}> = ({ review, index, isHideOnMobile }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <div
            key={`${review.user.name}`.toLowerCase().replaceAll(' ', '_')}
            className={clsx(
                isHideOnMobile ? 'hidden lg:flex' : 'flex',
                'relative w-full flex-shrink-0 flex-col items-stretch justify-between rounded bg-white p-8 shadow sm:w-full lg:w-1/2'
            )}
        >
            <Quote
                className={clsx(
                    'absolute left-2 top-2 h-8 w-8 stroke-current',
                    index % 2 ? 'text-cyprus' : 'text-mustard-default'
                )}
            />
            <Logo3T
                className={
                    'absolute -bottom-4 right-0 h-28 w-28 text-cyprus-default opacity-10'
                }
            />
            <div className={'mt-6 hidden lg:block'}>
                {review.snippet.length > 250 ? (
                    <div className={'inline select-none'}>
                        {expanded
                            ? review.snippet
                            : review.snippet.substring(0, 250)}{' '}
                        <button
                            className={
                                'ml-0.5 rounded bg-slate-200 p-1 leading-tight text-slate-600'
                            }
                            onClick={() => setExpanded(!expanded)}
                        >
                            ...
                        </button>
                    </div>
                ) : (
                    review.snippet
                )}
            </div>
            <div className="mt-6 lg:hidden">{review.snippet}</div>
            <div className={'my-4 flex justify-self-end'}>
                <div className={'flex-1'} />
                <div className={'flex'}>
                    {new Array(review.rating).fill({}).map((_, i) => (
                        <RatingStar
                            key={i.toFixed()}
                            className={clsx(
                                'h-5 w-5 fill-current',
                                index % 2
                                    ? 'text-cyprus'
                                    : 'text-mustard-default'
                            )}
                        />
                    ))}
                </div>
            </div>
            <div className={'flex items-center gap-2'}>
                <Image
                    src={review.user.thumbnail}
                    alt={`Profil Picture ${review.user.name}`}
                    width={40}
                    height={40}
                />
                <div className={'flex flex-col'}>
                    <span className={'font-title capitalize'}>
                        {review.user.name}
                    </span>
                    <span className={'text-sm italic text-neutral-400'}>
                        {review.date}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ReviewCard;
