'use client';

import { VerticaleSummary } from '@/services/solutions.service';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC, useEffect, useRef, useState } from 'react';
import Logo3T from '../../../public/logos/stratt-logo-ttt.svg';

const VerticaleCard: FC<{ verticale: VerticaleSummary }> = ({ verticale }) => {
    //NOTE_LT : create wrapping when "ASector/BSector" type of verticale is used
    const verticalName = verticale.name!.replace('/', ' / ');

    return (
        <Link
            className={
                'relative flex select-none rounded-lg bg-white p-3 shadow lg:min-w-[250px]'
            }
            href={'/' + verticale.slug!}
        >
            <Logo3T
                className={
                    'absolute left-2 top-0 h-16 w-16 text-cyprus-default opacity-20'
                }
            />
            <div
                className={'flex w-full flex-col items-center justify-between'}
            >
                <Image
                    src={
                        verticale.illustration?.url ||
                        '/illustations/not-found.svg'
                    }
                    alt={`Illustation pour ${verticale.name}`}
                    loading={'lazy'}
                    width={250}
                    height={250}
                />
                <h3>
                    <span className={'mb-1 mt-1.5 block text-center text-lg'}>
                        Pour les
                    </span>
                    <span
                        className={
                            'block text-center font-title text-lg font-bold text-cyprus sm:text-xl'
                        }
                    >
                        {verticalName}
                    </span>
                </h3>
            </div>
        </Link>
    );
};

// NOTE_LT : Att, mouse scrolling is not working here due to automatic carousel scroll
const VerticaleScrollComponent: FC<{ verticales: VerticaleSummary[] }> = ({
    verticales
}) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const [isScrollLeftDisabled, setIsScrollLeftDisabled] = useState(true);
    const [isScrollRightDisabled, setIsScrollRightDisabled] = useState(false);

    const updateScrollLevel = ({
        scrollLeft,
        clientWidth,
        scrollWidth
    }: {
        scrollLeft: number;
        clientWidth: number;
        scrollWidth: number;
    }) => {
        setIsScrollLeftDisabled(scrollLeft <= 1);
        setIsScrollRightDisabled(scrollLeft + clientWidth >= scrollWidth - 1);
    };

    const handleScroll = (
        dir: 'left' | 'right',
        distance = 250,
        behavior: ScrollBehavior = 'smooth'
    ) => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const newScrollLeft =
                dir === 'right'
                    ? Math.min(scrollWidth - clientWidth, scrollLeft + distance)
                    : Math.max(0, scrollLeft - distance);
            /* const newScrollLeft =
                scrollLeft + (dir === 'right' ? distance : -distance); */
            scrollRef.current?.scroll({
                left: newScrollLeft,
                behavior
            });

            updateScrollLevel({
                scrollLeft: newScrollLeft,
                clientWidth,
                scrollWidth
            });
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (scrollRef.current) {
                const { scrollLeft, clientWidth, scrollWidth } =
                    scrollRef.current;
                updateScrollLevel({ scrollLeft, clientWidth, scrollWidth });
            }
        };

        // Add resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /* const scrollLeft = (e: React.MouseEvent) => {
        e.preventDefault();
        scrollRef.current?.scroll({
            left: Math.max(0, scrollRef.current.scrollLeft - 250),
            behavior: 'smooth',
        });
    };

    const scrollRight = (e: React.MouseEvent) => {
        e.preventDefault();
        scrollRef.current?.scroll({
            left: scrollRef.current.scrollLeft + 250,
            behavior: 'smooth',
        });
    }; */

    const smoothScrollRight = (e: React.MouseEvent) => {
        e.preventDefault();
        timeoutRef.current = setInterval(() => {
            /* scrollRef.current?.scroll({
                left: scrollRef.current.scrollLeft + 10,
                behavior: 'smooth',
            }); */
            handleScroll('right', 1, 'auto');
        }, 18);
    };

    const onLeave = (e: React.MouseEvent) => {
        if (timeoutRef.current) {
            clearInterval(timeoutRef.current);
            timeoutRef.current = undefined;
        }
    };

    return (
        <div className={'flex w-full items-center gap-6'}>
            <button
                disabled={isScrollLeftDisabled}
                onClick={() => handleScroll('left')}
                className={
                    'hidden rounded-full text-white ring-2 ring-white disabled:opacity-50 lg:block'
                }
                aria-label="Défiler vers la gauche"
            >
                <ChevronLeftIcon className={'h-6 w-6'} />
            </button>

            <div
                onMouseEnter={smoothScrollRight}
                onClick={onLeave}
                onMouseLeave={onLeave}
                ref={scrollRef}
                className={
                    'no-scrollbar grid w-full grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-4 lg:flex lg:overflow-x-scroll'
                }
            >
                {verticales.map((verticale) => (
                    <VerticaleCard verticale={verticale} key={verticale.id} />
                ))}
            </div>

            <button
                disabled={isScrollRightDisabled}
                onClick={() => handleScroll('right')}
                className={
                    'hidden rounded-full text-white ring-2 ring-white disabled:opacity-50 lg:block'
                }
                aria-label="Défiler vers la droite"
            >
                <ChevronRightIcon className={'h-6 w-6'} />
            </button>
        </div>
    );
};

export default VerticaleScrollComponent;
