'use client';

import ReviewCard from '@/components/Review/ReviewCard';
import { Review } from '@/services/review.service';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { FC, useEffect, useRef, useState } from 'react';

const ReviewScrollList: FC<{ reviews: Review[] }> = ({ reviews }) => {
    const [isMobileShowMore, setIsMobileShowMore] = useState(false);
    const [isScrollLeftDisabled, setIsScrollLeftDisabled] = useState(true);
    const [isScrollRightDisabled, setIsScrollRightDisabled] = useState(false);

    const scrollRef = useRef<HTMLDivElement>(null);

    const handleScroll = (dir: 'left' | 'right', distance = 450) => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            const newScrollLeft =
                dir === 'right'
                    ? Math.min(scrollWidth - clientWidth, scrollLeft + distance)
                    : Math.max(0, scrollLeft - distance);
            /* const newScrollLeft =
                scrollLeft + (dir === 'right' ? distance : -distance); */
            scrollRef.current?.scroll({
                left: newScrollLeft,
                behavior: 'smooth'
            });
            /*  setIsScrollLeftDisabled(newScrollLeft <= 0);
            setIsScrollRightDisabled(
                newScrollLeft + clientWidth >= scrollWidth,
            ); */
        }
    };

    /*  const scrollLeft = (e: React.MouseEvent) => {
        e.preventDefault();
        scrollRef.current?.scroll({
            left: Math.max(0, scrollRef.current.scrollLeft - 450),
            behavior: 'smooth',
        });
    };

    const scrollRight = (e: React.MouseEvent) => {
        e.preventDefault();
        scrollRef.current?.scroll({
            left: scrollRef.current.scrollLeft + 450,
            behavior: 'smooth',
        });
    }; */

    useEffect(() => {
        const onScroll = () => {
            if (scrollRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } =
                    scrollRef.current;
                setIsScrollLeftDisabled(scrollLeft <= 1);
                setIsScrollRightDisabled(
                    scrollLeft + clientWidth >= scrollWidth - 1
                );
            }
        };

        const scrollElement = scrollRef.current;
        scrollElement?.addEventListener('scroll', onScroll);
        window.addEventListener('resize', onScroll);

        // Cleanup on component unmount
        return () => {
            scrollElement?.removeEventListener('scroll', onScroll);
            window.removeEventListener('resize', onScroll);
        };
    }, []);

    return (
        <>
            <div className={'flex w-full items-center gap-6'}>
                <button
                    disabled={isScrollLeftDisabled}
                    onClick={() => handleScroll('left')}
                    className={
                        'hidden rounded-full text-white ring-2 ring-white disabled:opacity-50 lg:block'
                    }
                    aria-label="Défiler vers la gauche"
                >
                    <ChevronLeftIcon className={'h-6 w-6'} />
                </button>

                <div
                    ref={scrollRef}
                    className={
                        'no-scrollbar grid h-fit w-full grid-cols-1 gap-3 overflow-y-clip sm:gap-4 lg:flex lg:overflow-x-scroll'
                    }
                >
                    {/* NOTE_LT : a shuffle was here and caused server client mismatched */}
                    {reviews.map((review: Review, index) => {
                        return (
                            <ReviewCard
                                key={review.link}
                                review={review}
                                index={index}
                                isHideOnMobile={!isMobileShowMore && index > 2}
                            />
                        );
                    })}
                </div>

                <button
                    disabled={isScrollRightDisabled}
                    onClick={() => handleScroll('right')}
                    className={
                        'hidden rounded-full text-white ring-2 ring-white disabled:opacity-50 lg:block'
                    }
                    aria-label="Défiler vers la droite"
                >
                    <ChevronRightIcon className={'h-6 w-6'} />
                </button>
            </div>
            <button
                className={
                    'mx-auto my-4 block font-title text-white underline underline-offset-4 lg:hidden'
                }
                onClick={() => setIsMobileShowMore(!isMobileShowMore)}
            >
                {isMobileShowMore ? 'Moins' : 'Plus'} d'avis
            </button>
        </>
    );
};

export default ReviewScrollList;
